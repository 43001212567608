/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//

// jQuery is added strictly for the Analytics (gya.js)
import $ from "jquery"
window.$ = window.jQuery = $

import "bootstrap/js/dist/modal"
import "bootstrap/js/dist/dropdown"

import { bindGYEvents } from "../gy-events"
import { startRails, freezeBackground, unfreezeBackground, initScrollTop } from "../shared"
import { triggerQuickBuy } from "../product/quickBuy"
import { initMainMenu, responsiveMenu } from "../header/mainMenu"
import { initMiniCartPanel } from "../pages/miniCart"
import { initHeightEqualizer } from "../shared"
import { loadWebFonts } from "../typography/fonts"


const GY = {
  loadShared: function () {
    loadWebFonts()
    startRails()
    bindGYEvents()
    initMainMenu()
    responsiveMenu()
    freezeBackground()
    unfreezeBackground()
    initMiniCartPanel()
    triggerQuickBuy()
    initHeightEqualizer()
    initScrollTop()
  },
  loadHomepage: function () {
    import("../initializers/homepageInitializer")
      .then((module) => { module.homepageInitializer() })
  },
  loadProductPage: function () {
    import("../initializers/productPageInitializer")
      .then((module) => { module.productPageInitializer() })
  },
  loadCatalog: function () {
    import("../initializers/catalogInitializer")
      .then((module) => { module.catalogInitializer() })
  },
  loadExpertsPage: function () {
    import("../initializers/expertsPageInitializer")
      .then((module) => { module.expertsPageInitializer() })
  },
  loadStaticPages: function () {
    import("../initializers/staticPagesInitializer")
      .then((module) => { module.staticPagesInitializer() })
  },
  loadOrder: function () {
    import("../initializers/orderInitializer")
      .then((module) => { module.staticPagesInitializer() })
  },
  loadCheckout: function () {
    import("../initializers/checkoutInitializer")
      .then((module) => { module.checkoutInitializer() })
  },
  loadArticlePage: function () {
    import("../initializers/articlePageInitializer")
      .then((module) => { module.articlePageInitializer() })
  }
}

GY.loadShared()

if (document.querySelector(".homepage")) {
  import("bootstrap/js/dist/tab")

  GY.loadHomepage()
}
else if (document.querySelector(".catalog-page")) {
  GY.loadCatalog()
}
else if (document.querySelector(".product-page")) {
  import("bootstrap/js/dist/tab")

  GY.loadProductPage()
}
else if (document.querySelector(".static-page")) {
  GY.loadStaticPages()
}
else if (document.querySelector(".checkout-2")) {
  GY.loadCheckout()
}
else if (document.querySelector(".wishlist-page")) {

}
else if (document.querySelector(".article-page")) {
  GY.loadArticlePage()
}
else if (document.querySelector(".experts-page")) {
  import("bootstrap/js/dist/collapse")

  GY.loadExpertsPage()
}
